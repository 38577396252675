<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>
                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI短视频脚本" />
                        <ai_select selectTitle="风格类型" :select_list="selectList" @getSelectVal="get_type" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">主题：</p>
                            <div class="upload_box">
                                <el-input v-model="form.business" placeholder="请简述短视频主题内容"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">内容描述：</p>
                            <div class="upload_box">
                                <el-input v-model="form.kernel" placeholder="如：几个人物、在什么场景下、做什么、怎么做等"></el-input>
                            </div>
                        </div>
                        <!-- <div class="form_ct flex">
                            <p class="upLoad_text">关键词</p>
                            <el-input v-model="form.keyword" placeholder="如:有趣的建筑"></el-input>
                        </div> -->
                        <button class="create_but" @click="FileData">生成内容</button>
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />

                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />

    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_select from '@/components/ai_select.vue'
import ai_title from '@/components/ai_title.vue'
import ai_text_content from '@/components/ai_text_content.vue'
export default {
    name: '',
    mounted() { this.getUserInfo() },
    components: {
        wordNav,
        ai_title,
        ai_select,
        ai_text_content
    },
    data() {
        return {
            form: {
                type: '',
                business: '',
                // keyword: '',
                kernel: '',
            },
            fullscreenLoading: false,
            contentData: {}, //内容
            selectList: [
                {
                    id: '美食',
                    name: '美食',
                },
                {
                    id: '自然',
                    name: '自然',
                },
                {
                    id: '专题',
                    name: '专题',
                },
                {
                    id: '历史',
                    name: '历史',
                },
                {
                    id: '生活',
                    name: '生活',
                },
                {
                    id: '宣传',
                    name: '宣传',
                },
                {
                    id: '其他',
                    name: '其他',
                },
            ],
            aiNumShow: false,
            aiPoupShow: false,

        }
    },
    methods: {
        get_type(val) {
            this.form.type = val
        },
        // 获取用户信息
        getUserInfo() {
            this.curlGet('/api/user/info').then((res) => {
                if (res.data.code) {
                    this.$user_info = res.data.data
                    this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                }
            })
        },
        FileData() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入平台',
            //         type: 'warning'
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入标题',
            //         type: 'warning'
            //     });
            // }
            // if (this.form.kesword == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning'
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            this.aiNumShow = false
            this.fullscreenLoading = true
            this.curlPost('/file/shortVideo_create', {
                type: this.form.type,
                business: this.form.business,
                // keyword: this.form.keyword,
                mobile: this.$user_info.mobile,
                kernel: this.form.kernel,

            }).then(res => {
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
                this.fullscreenLoading = false
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>